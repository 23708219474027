<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
       <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green"  @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hd"
          :items="ds.Array"
          :items-per-page="-1"
          class="elevation-1"
        >
        <template v-slot:body.append>
            <tr>
              <td colspan=3 class="text-right">รวม</td>
              <td class="text-right">{{ ds.B1000 }}</td>
              <td class="text-right">{{ ds.B500 }}</td>
              <td class="text-right">{{ ds.B100 }}</td>
              <td class="text-right">{{ ds.B50 }}</td>
              <td class="text-right">{{ ds.B20 }}</td>
              <td class="text-right">{{ ds.B10 }}</td>
              <td class="text-right">{{ ds.B5 }}</td>
              <td class="text-right">{{ ds.B2 }}</td>
              <td class="text-right">{{ ds.B1 }}</td>
              <td class="text-right">{{ ds.Total }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hd:[
        {
          text: "ประเภท",
          align: "left",
          sortable: true,
          value: "Type",
          width: 100,
        },
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "MoneyDate",
          width: 100,
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "Money.MoneyRemark",
          width: 200,
        },
        {
          text: "1000",
          align: "right",
          sortable: true,
          value: "Money.B1000",
          width: 20,
        },
        {
          text: "500",
          align: "right",
          sortable: true,
          value: "Money.B500",
          width: 20,
        },
        {
          text: "100",
          align: "right",
          sortable: true,
          value: "Money.B100",
          width: 20,
        },
        {
          text: "50",
          align: "right",
          sortable: true,
          value: "Money.B50",
          width: 20,
        },
        {
          text: "20",
          align: "right",
          sortable: true,
          value: "Money.B20",
          width: 20,
        },
        {
          text: "10",
          align: "right",
          sortable: true,
          value: "Money.B10",
          width: 20,
        },
        {
          text: "5",
          align: "lerightft",
          sortable: true,
          value: "Money.B5",
          width: 20,
        },
        {
          text: "2",
          align: "right",
          sortable: true,
          value: "Money.B2",
          width: 20,
        },
        {
          text: "1",
          align: "right",
          sortable: true,
          value: "Money.B1",
          width: 20,
        },
        {
          text: "รวม",
          align: "right",
          sortable: true,
          value: "Money.Total",
          width: 80,
        }
      ],
      ds: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select( this.dateStart, this.dateEnd);
    });
  },
  methods: {
    Search(){
      this.Select(this.dateStart,this.dateEnd)
    },
    Select(start, end) {
      axios
        .get(`/api/v1/select/Select/Money?DateStart=${start}&DateEnd=${end}&type=report`)
        .then(res => {
          if (res.status == 200) {
            this.ds = res.data;
          }
        });
    },
    Prints() {
      if(this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext : "รายงานการรับเงิน",
          hd: this.hd,
          ite: this.ds.Array,
          fot: [
            { text: "รวม", cols: 4 },
            { text: this.ds.B1000, cols: 1 },
            { text: this.ds.B500, cols: 1 },
            { text: this.ds.B100, cols: 1 },
            { text: this.ds.B50, cols: 1 },
            { text: this.ds.B20, cols: 1 },
            { text: this.ds.B10, cols: 1 },
            { text: this.ds.B5, cols: 1 },
            { text: this.ds.B2, cols: 1 },
            { text: this.ds.B1, cols: 1 },
            { text: this.ds.Total, cols: 1 }
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  }
};
</script>
<style>
</style>